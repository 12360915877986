chrome.action.onClicked.addListener(function () {
  chrome.tabs.create({ url: 'index.html' });
});


chrome.webNavigation.onDOMContentLoaded.addListener(function (details) {
  // don't counter search if not yahoo search and bing search 
  if (!details.url.includes('search.yahoo.com/search') && !details.url.includes('https://www.bing.com/search')) {
    //console.log(`Current URL is: `+details.url);
    return;
  }

  const pairs = details.url.split('?')[1].split('&');

  pairs.forEach(x => {
    const components = x.split('=');
    // don't counter search if key not in p (yahoo) or q (bing)
    if (components[0] !== 'p' && components[0] !== 'q') {
      return;
    }

    sendSearchHistory(components[1]);
  });
});

function sendSearchHistory (searchQuery: string) {
  //console.log(`User searched for ${searchQuery} on Yahoo/Bing.`);

  chrome.storage.local.get('token', (result) => {
    const token = result?.token?.token;

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/search?p=${searchQuery}`, {
      headers: {
        Authorization: token,
      },
    })
  });
}

chrome.runtime.onMessage.addListener(function (request, sender, sendResponse) {
  if (request.messageType === 'getSearchSuggestionsyahoo') {
    fetch(
      `https://sugg.search.yahoo.net/sg/?output=json&nresults=10&command=${request.value}`
    )
      .then(async (res) => {
        const data = await res.json();

        sendResponse({
          success: true,
          searchResults: data.gossip.results.map((x: any) => x.key) ?? [],
        });
      })
      .catch((error) => {
        sendResponse({
          success: false,
          error: error,
        });
      });
  }
  if (request.messageType === 'getSearchSuggestions') {
    fetch(
      `https://api.bing.com/osjson.aspx?query=${request.value}`
    )
      .then(async (res) => {
        const data = await res.json();        
        sendResponse({
          success: true,
          searchResults: data[1] ?? [],
        });
      })
      .catch((error) => {
        sendResponse({
          success: false,
          error: error,
        });
      });
  }
  return true;
});

chrome.runtime.onInstalled.addListener(function () {
  chrome.tabs.create({ url: 'index.html' });

  return false;
});

// chrome.runtime.setUninstallURL('https://webstars.org/post_uninstall');

chrome.runtime.onMessage.addListener(function (request, sender, sendResponse) {
  chrome.runtime.setUninstallURL(`${process.env.REACT_APP_API_BASE_URL}/extensions/uninstall/${request?.message?.code}`);
});

export {};
